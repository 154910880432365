.App {
  width: 100%;
  height: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
}

.btn.round {
  border-radius: 100px;
  width: 80px;
  height: 80px;
}

.btn--icon {
  background: none;
  border: none;
}

.select__container {
  min-width: 150px;
  max-width: 150px;
  margin: auto;
}

.pulse {
  animation: pulse 500ms infinite alternate;
}

.absolute-container {
  position: absolute;
  left: 0;
  top: 60px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background: white;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}
