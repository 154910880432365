.modal__container {
  position: fixed;
  top: 0%;
  left: 0%;
  font-size: 50px;
  width: 100%;
  height: 100%;
  background: none;
  justify-content: center;
  align-items: center;
  display: none;
  color: black;
  font-size: 16px;
  z-index: 999;
}

/* NOTE: I'm not sure that both the wrapper and content are necessary*/
.modal__wrapper {
  width: 95%;
  /* height: 80%; */
  min-width: 300px;
  max-width: 500px;
  min-height: 300px;
  max-height: 95%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal__wrapper::-webkit-scrollbar {
  display: none;
}

.modal__back {
  display: flex;
  border: none;
  position: absolute;
  left: 18px;
  top: 18px;
  background: none;
  font-size: 22px;
  width: 35px;
  height: 35px;
}

.modal__close {
  position: absolute;
  right: 2px;
  top: 5px;
  padding: 15px;
  border: none;
  background: none;
  z-index: 1;
  width: 60px;
  height: 60px;
}

.modal__close__bottom {
  position: absolute;
  bottom: 10px;
  left: 50%;
  font-size: 25px;
  width: 160px;
  font-weight: bold;
  margin-left: -80px;
  background: white;
  border: none;
  border-radius: 10px;
}

.modal__container.open {
  display: flex;
}

.modal__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.modal__title {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  /* display: block;
  margin: auto; */
  display: flex;
  justify-content: center;
}

.modal__title > div {
  /* display: inline-block;
  margin: auto; */
  /* padding: 15px;
  border: 1px solid black; */
}

.modal__content {
  height: 100%;
  box-sizing: border-box;
  min-height: 300px;
  background: white;
  border-radius: 30px;
  padding: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  /* border: 10px solid black; */
}

.modal__description {
  margin: 20px 0px;
  font-size: 1.3rem;
  text-align: center;
}

/* @media (min-width: 786px) {
  /* .modal__title {
    font-size: 40px;
  } 

  .modal__description {
    font-size: 28px;
    padding: 40px 30px;
  }
} */

.modal__bg {
  background-color: #dbe7db;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
}

.modal__block {
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  /* margin-top: -80px; */
}

.modal__block.columns {
  flex-direction: column;
}

.modal__block div {
  /* margin-top: 10px; */
}

.modal__block__title {
  font-size: 18px;
  font-weight: bold;
}

.modal__image-container {
  position: relative;
  width: 100%;
}

.modal__image-container img {
  width: 100%;
}

.modal__input-container {
  margin: 20px 0px;
}

.modal__next-image {
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 20px;
  border-radius: 30px;
  padding: 10px;
  background: white;
}

.modal__button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 25px;
}

.modal__button-container--stacked {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__button-container--stacked > button {
  margin-top: 8px;
}

.modal__button-container--stacked > button:nth-child(1) {
  margin: 0px;
}

.modal__svg-container {
  width: 100%;
  height: 100%;
  max-width: 520px;
}

.modal__svg-container > svg {
  height: 100%;
  width: 100%;
}

.modal__error {
  text-align: center;
  color: var(--red);
  margin-top: 20px;
}

.yaytso-view {
  transform: scale(0);
  /* animation: in 500ms forwards; */
}

.yaytsoId__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.yaytsoId__container > div {
  background-color: #ff4747;
  color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  flex: 0 0 30%;
  transition: background-color 500ms;
  cursor: pointer;
}

.yaytsoId__container > div:hover {
  background-color: black;
  /* color: w; */
}

.PhoneInputInput {
  font-size: 1.3rem;
}

.pulse-infinite {
  animation: in 2000ms infinite ease-in-out;
}

.spin-infinite {
  animation: spin 1000ms infinite ease-in-out;
}

.PhoneInputCountry {
  padding-right: 10px;
}

@keyframes in {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fade-in-enter {
  transform: scale(0);
}

.fade-in-enter-active {
  transform: scale(1);
  transition: transform 200ms;
}
.fade-in-exit {
  transform: scale(1);
}
.fade-in-exit-active {
  transform: scale(0);
  transition: transform 200ms;
}
