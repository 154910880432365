.wallet__root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.eggvatar__container {
  display: flex;
  justify-content: center;
  background-color: black;
  max-height: 100px;
}

.eggvatar__container img {
  width: 100px;
}

.wallet__container {
  height: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
}

.wallet__user-info {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  margin-top: 10px;
  max-width: 610px;
  /* flex-wrap: wrap; */
  /* margin: 20px 10px; */
  /* border-bottom: 1px solid; */
  /* padding-bottom: 20px; */
}

.wallet__address {
  word-break: break-all;
  /* margin: 0px 25px; */
  letter-spacing: 4.1px;
  flex: 0 0 50%;
}

.wallet__address__wrapper {
  min-width: 150px;
  max-width: 150px;
  margin: auto;
  background: black;
  color: white;
  padding: 10px;
}

.wallet__phone {
  font-size: 1.3rem;
  flex: 0 0 50%;
  text-align: center;
}

.wallet__connect-container {
  text-align: center;
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wallet__connect-container div,
.wallet__connect-container button {
  margin-top: 15px;
}

.wallet__connect-container div:first-of-type,
.wallet__connect-container button:first-of-type {
  margin-top: 0px;
}

.wallet__add-auth__container {
  flex: 0 0 100%;
  margin-top: 20px;
}

.wallet__egg-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: auto;
  /* padding-bottom: 10%; REFACTOR */
  overflow-x: hidden;
  background-color: black;
}

.wallet__egg-container .wallet__egg-wrapper {
  flex: 0 0 80%;
  box-sizing: border-box;
  text-align: center;
  max-width: 300px;
  height: 100%;
}

.wallet__title {
  font-weight: bold;
  letter-spacing: 5px;
  text-align: center;
  font-size: 1.5rem;
}

.wallet__egg-wrapper {
  position: relative;
  /* border-radius: 30px; */
  padding: 20px 0px 40px 0px;
  /* box-shadow: rgb(0 0 0) 0px 0px 31px; */
  margin: 40px;
  background-color: black;
}

.wallet__nft-tag {
  font-weight: bold;
  font-size: 2rem;
  color: black;
  position: absolute;
  top: 20%;
  left: 5%;
}

.loading-dot__container {
  /* background: black; */
  justify-content: center;
  display: flex;
  height: 100%;
  flex: 1 0 100%;
  align-items: center;
}

.loading-dot__jank-container {
  max-width: unset;
  margin-left: 50%;
  margin-top: 40%;
  min-height: 130px;
}

.wallet__container--body-cta {
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-top: 10%;
  font-weight: bold;
}

.wallet__container-no-egg {
  font-size: 1.5rem;
  width: 70%;
  /* background: #ff2a2a; */
  color: white;
  padding: 30px;
  border-radius: 20px;
  font-weight: bold;
}
