@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap);
:root {
  --yellow: #f1efb0;
  --off-white: #f5efef;
  --grey: rgb(143, 143, 143);
  --pink: #e4cbcf;
  --red: #ff4c38;
  --pale-red: #ffb6b6;
  --green: #99ff7f;
}

body {
  margin: 0;
  font-family: Sen;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.firebase-emulator-warning {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root,
.App {
  /* height: 100%; */
  width: 100%;
  background: white;
  color: black;
  /* overflow: hidden; */
  overflow-x: hidden !important;
}

@media screen and (max-width: 768px) {
  html,
  body,
  #root {
    font-size: 14px;
  }
}

input {
  font-size: 1.3rem;
  padding: 5px;
}

button,
.icon * {
  transition: fill 500ms, -webkit-filter 500ms;
  transition: filter 500ms, fill 500ms;
  transition: filter 500ms, fill 500ms, -webkit-filter 500ms;
  cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
  button:hover {
    -webkit-filter: invert(100%);
            filter: invert(100%);
  }
  .icon:hover * {
    fill: #ff4c38;
    fill: var(--red);
  }
}

input[type="file"] {
  display: none;
}

input[type="text"],
input[type="tel"] {
  width: 90%;
  height: 40px;
  display: block;
  margin: auto;
  border: 2px solid;
  border-radius: 10px;
  font-size: 18px;
  padding: 0px 10px;
  max-width: 320px;
  border: 2px solid;
  border-radius: 0px;
}

select {
  border: none;
  background: black;
  color: white;
  padding: 10px;
  font-weight: bold;
  letter-spacing: 1px;
}

.grecaptcha-badge {
  visibility: hidden;
}

.dot-typing {
  position: relative;
  left: -10001px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  animation: dotTyping 1.5s infinite linear;
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
  50% {
    box-shadow: 9984px 0 0 0 white, 9999px -10px 0 0 white, 10014px 0 0 0 white;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px -10px 0 0 white;
  }
  100% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
}

.dot-typing-inverse {
  position: relative;
  left: -10001px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: black;
  color: black;
  box-shadow: 9984px 0 0 0 black, 9999px 0 0 0 black, 10014px 0 0 0 black;
  animation: dotTypingInverse 1.5s infinite linear;
}

@keyframes dotTypingInverse {
  0% {
    box-shadow: 9984px 0 0 0 black, 9999px 0 0 0 black, 10014px 0 0 0 black;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 black, 9999px 0 0 0 black, 10014px 0 0 0 black;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 black, 9999px 0 0 0 black, 10014px 0 0 0 black;
  }
  50% {
    box-shadow: 9984px 0 0 0 black, 9999px -10px 0 0 black, 10014px 0 0 0 black;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 black, 9999px 0 0 0 black, 10014px 0 0 0 black;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 black, 9999px 0 0 0 black, 10014px -10px 0 0 black;
  }
  100% {
    box-shadow: 9984px 0 0 0 black, 9999px 0 0 0 black, 10014px 0 0 0 black;
  }
}

button.btn {
  font-size: 1.2rem;
  background: black;
  color: white;
  border-radius: 30px;
  border: none;
  width: 130px;
  height: 50px;
  font-weight: bold;
  font-family: Sen;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

button.btn.inverse {
  color: black;
  background-color: white;
}

button.btn.xs {
  width: 80px;
  height: 30px;
  font-size: 1rem;
}

button.btn.s {
  width: 100px;
}

button.btn.md {
  width: 200px;
}

button.btn.lg {
  width: 250px;
}

button.btn.flex {
  /* width: 50%; */
  margin: auto;
  padding: 5px;
  height: auto;
}

button.btn.flex2 {
  width: 100%;
  /* margin: auto; */
  /* padding: 10px; */
  /* height: 50px; */
  max-width: 300px;
  /* margin-bottom: 10px; */
  font-size: 1.1rem;
}

button.btn.flex3 {
  width: 100%;
  margin: auto;
  /* padding: 10px; */
  /* height: 50px; */
  max-width: 200px;
  /* margin-bottom: 10px; */
  font-size: 1.3rem;
}

.floating-button-container {
  position: absolute;
}

button.btn.anti-state {
  font-size: 1rem;
  background: white;
  border-radius: 30px;
  border: none;
  width: 130px;
  height: 50px;
  font-weight: bold;
  color: #f55959;
}

button.btn.danger {
  background-color: #f55959;
  border: 1px solid white;
}

button.btn.disabled {
  background-color: var(--grey);
  border: 1px solid var(--grey);
  color: var(--off-white);
}

button.info-button {
  background: black;
  color: white;
  font-size: 2rem;
  padding: 0px;
  border-radius: 60%;
  position: absolute;
  left: 20px;
  top: 20px;
  border: none;
  width: 40px;
  height: 40px;
  font-weight: bold;
}

/* FADE */
.fade-enter {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}

/* SLIDE */
.slide-enter {
  transform: translateX(100%);
  opacity: 0;
}
.slide-exit {
  transform: translateX(0%);
  opacity: 1;
}
.slide-enter-active {
  transform: translateX(0%);
  opacity: 1;
}
.slide-exit-active {
  transform: translateX(-100%);
  opacity: 0;
}
.slide-enter-active {
  transition: transform 500ms, opacity 2000ms;
}
.slide-exit-active {
  transition: transform 500ms, opacity 300ms;
}

/* SLIDE_BACK */
.slide-back-enter {
  transform: translateX(-100%);
  opacity: 0;
}
.slide-back-exit {
  transform: translateX(0%);
  opacity: 1;
}
.slide-back-enter-active {
  transform: translateX(0%);
  opacity: 1;
}
.slide-back-exit-active {
  transform: translateX(100%);
  opacity: 0;
}
.slide-back-enter-active {
  transition: transform 500ms, opacity 2000ms;
}
.slide-back-exit-active {
  transition: transform 500ms, opacity 300ms;
}

.modal__container {
  position: fixed;
  top: 0%;
  left: 0%;
  font-size: 50px;
  width: 100%;
  height: 100%;
  background: none;
  justify-content: center;
  align-items: center;
  display: none;
  color: black;
  font-size: 16px;
  z-index: 999;
}

/* NOTE: I'm not sure that both the wrapper and content are necessary*/
.modal__wrapper {
  width: 95%;
  /* height: 80%; */
  min-width: 300px;
  max-width: 500px;
  min-height: 300px;
  max-height: 95%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal__wrapper::-webkit-scrollbar {
  display: none;
}

.modal__back {
  display: flex;
  border: none;
  position: absolute;
  left: 18px;
  top: 18px;
  background: none;
  font-size: 22px;
  width: 35px;
  height: 35px;
}

.modal__close {
  position: absolute;
  right: 2px;
  top: 5px;
  padding: 15px;
  border: none;
  background: none;
  z-index: 1;
  width: 60px;
  height: 60px;
}

.modal__close__bottom {
  position: absolute;
  bottom: 10px;
  left: 50%;
  font-size: 25px;
  width: 160px;
  font-weight: bold;
  margin-left: -80px;
  background: white;
  border: none;
  border-radius: 10px;
}

.modal__container.open {
  display: flex;
}

.modal__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.modal__title {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  /* display: block;
  margin: auto; */
  display: flex;
  justify-content: center;
}

.modal__title > div {
  /* display: inline-block;
  margin: auto; */
  /* padding: 15px;
  border: 1px solid black; */
}

.modal__content {
  height: 100%;
  box-sizing: border-box;
  min-height: 300px;
  background: white;
  border-radius: 30px;
  padding: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  /* border: 10px solid black; */
}

.modal__description {
  margin: 20px 0px;
  font-size: 1.3rem;
  text-align: center;
}

/* @media (min-width: 786px) {
  /* .modal__title {
    font-size: 40px;
  } 

  .modal__description {
    font-size: 28px;
    padding: 40px 30px;
  }
} */

.modal__bg {
  background-color: #dbe7db;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
}

.modal__block {
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  /* margin-top: -80px; */
}

.modal__block.columns {
  flex-direction: column;
}

.modal__block div {
  /* margin-top: 10px; */
}

.modal__block__title {
  font-size: 18px;
  font-weight: bold;
}

.modal__image-container {
  position: relative;
  width: 100%;
}

.modal__image-container img {
  width: 100%;
}

.modal__input-container {
  margin: 20px 0px;
}

.modal__next-image {
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 20px;
  border-radius: 30px;
  padding: 10px;
  background: white;
}

.modal__button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 25px;
}

.modal__button-container--stacked {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__button-container--stacked > button {
  margin-top: 8px;
}

.modal__button-container--stacked > button:nth-child(1) {
  margin: 0px;
}

.modal__svg-container {
  width: 100%;
  height: 100%;
  max-width: 520px;
}

.modal__svg-container > svg {
  height: 100%;
  width: 100%;
}

.modal__error {
  text-align: center;
  color: var(--red);
  margin-top: 20px;
}

.yaytso-view {
  transform: scale(0);
  /* animation: in 500ms forwards; */
}

.yaytsoId__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.yaytsoId__container > div {
  background-color: #ff4747;
  color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  flex: 0 0 30%;
  transition: background-color 500ms;
  cursor: pointer;
}

.yaytsoId__container > div:hover {
  background-color: black;
  /* color: w; */
}

.PhoneInputInput {
  font-size: 1.3rem;
}

.pulse-infinite {
  animation: in 2000ms infinite ease-in-out;
}

.spin-infinite {
  animation: spin 1000ms infinite ease-in-out;
}

.PhoneInputCountry {
  padding-right: 10px;
}

@keyframes in {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fade-in-enter {
  transform: scale(0);
}

.fade-in-enter-active {
  transform: scale(1);
  transition: transform 200ms;
}
.fade-in-exit {
  transform: scale(1);
}
.fade-in-exit-active {
  transform: scale(0);
  transition: transform 200ms;
}

.overlay__root {
  position: absolute;
  top: 0px;
  left: 0px;
  background: none;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.overlay__container {
  display: grid;
  grid-template-columns: 10% 80% 10%;
  grid-template-rows: 100px 1fr 100px;
  grid-template-areas:
    "ltop mtop rtop"
    "lside middle rside"
    "lbottom mbottom rbottom";
  height: 100%;
  align-items: center;
  justify-items: center;
}

.overlay__container div {
  /* border: 2px dotted black; */
}

.overlay__right {
  pointer-events: all;
  grid-area: rside;
}

.overlay__bottom--m {
  pointer-events: all;
  grid-area: mbottom;
}

.nav {
  display: flex;
  justify-content: space-between;
  background-color: white;
  text-align: center;
}

.nav a {
  color: #ffffff;
  text-decoration: none;
  /* padding: 8px; */
  transition: color 1s, background 1s, border-bottom 1s;
  /* font-size: 1.1rem; */
  /* font-weight: bold; */
  /* width: 100px; */
  flex-grow: 1;
  background-color: black;
}

a.active {
  color: black;
  background-color: white;
}

a div {
  background-color: white;
  margin: 20px auto;
}

a.active div {
  background-color: black;
}

.nav__container {
  grid-area: lbottom / lbottom / rbottom / rbottom;
  display: flex;
  justify-content: space-around;
  align-items: center;
  pointer-events: all;
}

.wallet__root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.eggvatar__container {
  display: flex;
  justify-content: center;
  background-color: black;
  max-height: 100px;
}

.eggvatar__container img {
  width: 100px;
}

.wallet__container {
  height: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
}

.wallet__user-info {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  margin-top: 10px;
  max-width: 610px;
  /* flex-wrap: wrap; */
  /* margin: 20px 10px; */
  /* border-bottom: 1px solid; */
  /* padding-bottom: 20px; */
}

.wallet__address {
  word-break: break-all;
  /* margin: 0px 25px; */
  letter-spacing: 4.1px;
  flex: 0 0 50%;
}

.wallet__address__wrapper {
  min-width: 150px;
  max-width: 150px;
  margin: auto;
  background: black;
  color: white;
  padding: 10px;
}

.wallet__phone {
  font-size: 1.3rem;
  flex: 0 0 50%;
  text-align: center;
}

.wallet__connect-container {
  text-align: center;
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wallet__connect-container div,
.wallet__connect-container button {
  margin-top: 15px;
}

.wallet__connect-container div:first-of-type,
.wallet__connect-container button:first-of-type {
  margin-top: 0px;
}

.wallet__add-auth__container {
  flex: 0 0 100%;
  margin-top: 20px;
}

.wallet__egg-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: auto;
  /* padding-bottom: 10%; REFACTOR */
  overflow-x: hidden;
  background-color: black;
}

.wallet__egg-container .wallet__egg-wrapper {
  flex: 0 0 80%;
  box-sizing: border-box;
  text-align: center;
  max-width: 300px;
  height: 100%;
}

.wallet__title {
  font-weight: bold;
  letter-spacing: 5px;
  text-align: center;
  font-size: 1.5rem;
}

.wallet__egg-wrapper {
  position: relative;
  /* border-radius: 30px; */
  padding: 20px 0px 40px 0px;
  /* box-shadow: rgb(0 0 0) 0px 0px 31px; */
  margin: 40px;
  background-color: black;
}

.wallet__nft-tag {
  font-weight: bold;
  font-size: 2rem;
  color: black;
  position: absolute;
  top: 20%;
  left: 5%;
}

.loading-dot__container {
  /* background: black; */
  justify-content: center;
  display: flex;
  height: 100%;
  flex: 1 0 100%;
  align-items: center;
}

.loading-dot__jank-container {
  max-width: unset;
  margin-left: 50%;
  margin-top: 40%;
  min-height: 130px;
}

.wallet__container--body-cta {
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-top: 10%;
  font-weight: bold;
}

.wallet__container-no-egg {
  font-size: 1.5rem;
  width: 70%;
  /* background: #ff2a2a; */
  color: white;
  padding: 30px;
  border-radius: 20px;
  font-weight: bold;
}

.prompt__container {
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  max-width: 500px;
  padding: 10px 30px;
  color: white;
  opacity: 0.8;
  background-color: rgb(228, 93, 93);
  top: 20%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.lat-lng__container {
  position: absolute;
  bottom: 20%;
  background: black;
  width: 300px;
  max-width: 360px;
  font-size: 1.6rem;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  color: white;
  border-radius: 11px;
}

.user-marker {
  pointer-events: none;
}

.App {
  width: 100%;
  height: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
}

.btn.round {
  border-radius: 100px;
  width: 80px;
  height: 80px;
}

.btn--icon {
  background: none;
  border: none;
}

.select__container {
  min-width: 150px;
  max-width: 150px;
  margin: auto;
}

.pulse {
  animation: pulse 500ms infinite alternate;
}

.absolute-container {
  position: absolute;
  left: 0;
  top: 60px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background: white;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}

.egg__container {
  display: grid;
  grid-template-rows: 1fr 15%;
  height: 100%;
  flex: 1 0 100%;
  position: relative;
  max-width: 700px;
}

.egg__preview-container {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
}

.egg__pen-wrapper {
  margin: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background: black;
  border-radius: 60px;
  width: 80px;
  height: 80px;
  padding: 16px;
  box-sizing: border-box;
}

.egg__details {
  /* position: absolute;
  left: 20px;
  top: 25%; */
  font-size: 1.5rem;
}

.canvas__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.egg__preview-close-icon {
  border: none;
  background: none;
  position: absolute;
  right: -2px;
  top: 2px;
  width: 40px;
  height: 40px;
  mix-blend-mode: difference;
}

.egg__preview-close-icon > svg * {
  stroke: white;
}

#expand-button {
  border: none;
  background: none;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40px;
  height: 40px;
  mix-blend-mode: difference;
}

#expand-button > svg * {
  fill: white;
}

.upload-label {
  color: white;
  background-color: black;
  text-decoration: none;
  margin: 10px;
  padding: 10px 30px;
  transition: color 1s, background 1s;
  border-radius: 2rem;
  width: 180px;
  border: 1px solid black;
  font-size: 1.5rem;
  transition: color 1s, background-color 1s;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.upload-label:hover {
  color: black;
  background-color: white;
}

#preview-canvas {
  /* position: absolute;
  left: 10px;
  top: 10px; */
  border: 1px solid black;
  width: 100%;
  height: 100%;
}

.slider__container {
  position: absolute;
  right: 0px;
  top: 9px;
  width: 50%;
  text-align: right;
  padding-right: 10px;
  font-size: 2rem;
}

.slider {
  -webkit-appearance: none;
  height: 15px;
  /* border-radius: 5px; */
  background: black;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  padding: 10px;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

.egg-view__container {
  height: 100%;
  position: relative;
  max-width: 700px;
  width: 100%;
}

.egg-view__canvas__container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.egg-view__name {
  position: absolute;
  top: 23px;
  font-size: 2rem;
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.egg-view__description {
  position: absolute;
}

.egg-view__mint-button-container {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.egg-view__mint-button-container button {
  flex: 0 0 42%;
}

.egg-view__bottom-container {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.egg-view__nft-button-container {
  width: 100%;
}

.nft-tag {
  color: white;
  background: black;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
}

.opensea-link {
  color: white;
  background: #2081e2;
  margin: auto;
  text-align: center;
  width: 100%;
  display: block;
  text-decoration: none;
  padding: 1rem;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 1.5rem;
}

.message__container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  padding: 60px;
  text-align: center;
}

.mint__receipt {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-flow: auto;
  width: 100%;
  font-size: 1rem;
  grid-row-gap: 1rem;
  align-items: center;
  justify-content: center;
  margin: 30px;
  text-align: left;
}

.mint__receipt div {
  overflow-wrap: break-word;
  /* width: 100px; */
}

.mint__receipt * {
  grid-column: 2;
}

.mint__receipt__img {
  grid-column: 1;
  grid-row: 2/4;
  width: 100%;
}

.mint__receipt__id {
  grid-column: 1;
  text-align: center;
  font-size: 4rem;
  font-weight: bold;
}

.mint__receipt a {
  font-size: 0.8rem;
  font-weight: 500;
  color: red;
}

.mint__receipt__block-number {
  letter-spacing: 1px;
  font-weight: 500;
}

.mint__icon-container {
  padding: 10px 30px;
  display: flex;
  justify-content: center;
}

.mint__icon-container svg {
  max-width: 130px;
}

.guestlist__container {
}

.guestlist__egg-name {
  font-size: 2rem;
  width: 100%;
  text-align: center;
  font-weight: bold;
  background: black;
  color: white;
  padding: 20px 0px;
}

.guestlist__heading {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  background: red;
  margin: 0px 20px;
  padding: 10px;
  color: white;
  border-radius: 10px;
}

.guestlist__text {
  font-size: 1.3rem;
  padding: 10px 30px;
  background: black;
  color: white;
  margin: 19px;
  border-radius: 10px;
  font-weight: bold;
}

.guestlist__container img {
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
  border-radius: 10%;
}

.input--label {
  font-size: 1.5rem;
  margin: 7px 15px;
}

