.egg__container {
  display: grid;
  grid-template-rows: 1fr 15%;
  height: 100%;
  flex: 1 0 100%;
  position: relative;
  max-width: 700px;
}

.egg__preview-container {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
}

.egg__pen-wrapper {
  margin: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background: black;
  border-radius: 60px;
  width: 80px;
  height: 80px;
  padding: 16px;
  box-sizing: border-box;
}

.egg__details {
  /* position: absolute;
  left: 20px;
  top: 25%; */
  font-size: 1.5rem;
}

.canvas__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.egg__preview-close-icon {
  border: none;
  background: none;
  position: absolute;
  right: -2px;
  top: 2px;
  width: 40px;
  height: 40px;
  mix-blend-mode: difference;
}

.egg__preview-close-icon > svg * {
  stroke: white;
}

#expand-button {
  border: none;
  background: none;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40px;
  height: 40px;
  mix-blend-mode: difference;
}

#expand-button > svg * {
  fill: white;
}

.upload-label {
  color: white;
  background-color: black;
  text-decoration: none;
  margin: 10px;
  padding: 10px 30px;
  transition: color 1s, background 1s;
  border-radius: 2rem;
  width: 180px;
  border: 1px solid black;
  font-size: 1.5rem;
  transition: color 1s, background-color 1s;
  cursor: pointer;
  user-select: none;
}

.upload-label:hover {
  color: black;
  background-color: white;
}

#preview-canvas {
  /* position: absolute;
  left: 10px;
  top: 10px; */
  border: 1px solid black;
  width: 100%;
  height: 100%;
}

.slider__container {
  position: absolute;
  right: 0px;
  top: 9px;
  width: 50%;
  text-align: right;
  padding-right: 10px;
  font-size: 2rem;
}

.slider {
  -webkit-appearance: none;
  height: 15px;
  /* border-radius: 5px; */
  background: black;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  padding: 10px;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}
