.egg-view__container {
  height: 100%;
  position: relative;
  max-width: 700px;
  width: 100%;
}

.egg-view__canvas__container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.egg-view__name {
  position: absolute;
  top: 23px;
  font-size: 2rem;
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.egg-view__description {
  position: absolute;
}

.egg-view__mint-button-container {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.egg-view__mint-button-container button {
  flex: 0 0 42%;
}

.egg-view__bottom-container {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.egg-view__nft-button-container {
  width: 100%;
}

.nft-tag {
  color: white;
  background: black;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
}

.opensea-link {
  color: white;
  background: #2081e2;
  margin: auto;
  text-align: center;
  width: 100%;
  display: block;
  text-decoration: none;
  padding: 1rem;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 1.5rem;
}
