@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap");
:root {
  --yellow: #f1efb0;
  --off-white: #f5efef;
  --grey: rgb(143, 143, 143);
  --pink: #e4cbcf;
  --red: #ff4c38;
  --pale-red: #ffb6b6;
  --green: #99ff7f;
}

body {
  margin: 0;
  font-family: Sen;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.firebase-emulator-warning {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root,
.App {
  /* height: 100%; */
  width: 100%;
  background: white;
  color: black;
  /* overflow: hidden; */
  overflow-x: hidden !important;
}

@media screen and (max-width: 768px) {
  html,
  body,
  #root {
    font-size: 14px;
  }
}

input {
  font-size: 1.3rem;
  padding: 5px;
}

button,
.icon * {
  transition: filter 500ms, fill 500ms;
  cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
  button:hover {
    filter: invert(100%);
  }
  .icon:hover * {
    fill: var(--red);
  }
}

input[type="file"] {
  display: none;
}

input[type="text"],
input[type="tel"] {
  width: 90%;
  height: 40px;
  display: block;
  margin: auto;
  border: 2px solid;
  border-radius: 10px;
  font-size: 18px;
  padding: 0px 10px;
  max-width: 320px;
  border: 2px solid;
  border-radius: 0px;
}

select {
  border: none;
  background: black;
  color: white;
  padding: 10px;
  font-weight: bold;
  letter-spacing: 1px;
}

.grecaptcha-badge {
  visibility: hidden;
}

.dot-typing {
  position: relative;
  left: -10001px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  animation: dotTyping 1.5s infinite linear;
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
  50% {
    box-shadow: 9984px 0 0 0 white, 9999px -10px 0 0 white, 10014px 0 0 0 white;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px -10px 0 0 white;
  }
  100% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
}

.dot-typing-inverse {
  position: relative;
  left: -10001px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: black;
  color: black;
  box-shadow: 9984px 0 0 0 black, 9999px 0 0 0 black, 10014px 0 0 0 black;
  animation: dotTypingInverse 1.5s infinite linear;
}

@keyframes dotTypingInverse {
  0% {
    box-shadow: 9984px 0 0 0 black, 9999px 0 0 0 black, 10014px 0 0 0 black;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 black, 9999px 0 0 0 black, 10014px 0 0 0 black;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 black, 9999px 0 0 0 black, 10014px 0 0 0 black;
  }
  50% {
    box-shadow: 9984px 0 0 0 black, 9999px -10px 0 0 black, 10014px 0 0 0 black;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 black, 9999px 0 0 0 black, 10014px 0 0 0 black;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 black, 9999px 0 0 0 black, 10014px -10px 0 0 black;
  }
  100% {
    box-shadow: 9984px 0 0 0 black, 9999px 0 0 0 black, 10014px 0 0 0 black;
  }
}
