button.btn {
  font-size: 1.2rem;
  background: black;
  color: white;
  border-radius: 30px;
  border: none;
  width: 130px;
  height: 50px;
  font-weight: bold;
  font-family: Sen;
  user-select: none;
}

button.btn.inverse {
  color: black;
  background-color: white;
}

button.btn.xs {
  width: 80px;
  height: 30px;
  font-size: 1rem;
}

button.btn.s {
  width: 100px;
}

button.btn.md {
  width: 200px;
}

button.btn.lg {
  width: 250px;
}

button.btn.flex {
  /* width: 50%; */
  margin: auto;
  padding: 5px;
  height: auto;
}

button.btn.flex2 {
  width: 100%;
  /* margin: auto; */
  /* padding: 10px; */
  /* height: 50px; */
  max-width: 300px;
  /* margin-bottom: 10px; */
  font-size: 1.1rem;
}

button.btn.flex3 {
  width: 100%;
  margin: auto;
  /* padding: 10px; */
  /* height: 50px; */
  max-width: 200px;
  /* margin-bottom: 10px; */
  font-size: 1.3rem;
}

.floating-button-container {
  position: absolute;
}

button.btn.anti-state {
  font-size: 1rem;
  background: white;
  border-radius: 30px;
  border: none;
  width: 130px;
  height: 50px;
  font-weight: bold;
  color: #f55959;
}

button.btn.danger {
  background-color: #f55959;
  border: 1px solid white;
}

button.btn.disabled {
  background-color: var(--grey);
  border: 1px solid var(--grey);
  color: var(--off-white);
}

button.info-button {
  background: black;
  color: white;
  font-size: 2rem;
  padding: 0px;
  border-radius: 60%;
  position: absolute;
  left: 20px;
  top: 20px;
  border: none;
  width: 40px;
  height: 40px;
  font-weight: bold;
}
